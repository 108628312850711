<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>
        <div class="white-bg ">
          <div class="news-main">
            <div class="main_left">
              <div class="menu">
                <div class="submenu menu_title">
                  <a href="javascript:void(0);">
                    <img src="@/assets/images/index/icon/notice.png"> 政策法规
                  </a>
                </div>

                <div class="submenu menu-list" v-for="(item,index) in gonggaoList" :class="{
                    'active':currentIndex == index
                  }" :key="index">
                  <a href="javascript:void(0);" @click="changeSmallType(index)">
                    {{item.smallTypeName}}
                  </a>
                </div>

              </div>

            </div>
            <div class="main_right">
              <div class="current">
                <div class="position">
                  <a href="/index">
                    首页
                  </a>
                  &gt;
                  <a href="Javascript:void(0);">
                    政策法规
                  </a>
                  &gt;
                  <a href="Javascript:void(0);" @click="changeSmallType(currentIndex)" style="color: #0d5799">
                    {{currentSmallTypeName}}
                  </a>
                </div>
              </div>
              <div class="conlistComm banner">

                <!-- list -->
                <div class=" mt-2">
                  <a href="javascript:void(0);" @click="openNews(item.newsId)" v-for="(item,i) in listBox" :key="i">
                    <div class="List-box flexList" >
                      <div class="center-list-title flexList"><i class="iconfont icon-xiaofangkuai"></i>
                        <div class="line1 center-list-name">{{item.newsTitle| ellipsis(50)}}</div>
                        <div class="center-list-time">{{item.createTime}}</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div style="padding: 30px;">
                <el-pagination
                        @size-change="handleSizeChange"
                        background
                        @current-change="handleCurrentChange"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        layout="total, sizes,jumper, prev, pager, next"
                        :total="query.allCount"
                >
                </el-pagination>
              </div>
            </div>
          </div>

        </div>




      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from "./index-header.vue"
  import Footer from "./index-footer.vue"
  import Navbar from "./navbar.vue"
  import commonPagination from "../common/commonPagination";

  import { getNewsListPage,getNewsSmallTypeList } from "@/api/web/web.js";

  export default {
    name: "index",
    data() {
      return {
        query: {
          nowPage: 1,
          pageSize: 20,
          allCount: 0,
          indexName: "zcfg", // 政策法规大类索引名称
          smallTypeId: "",
          isPaging: 1 // 分页请求
        },
        currentSmallTypeName: "", // 当前的二级分类
        currentIndex: -1, // 当前二级分类索引
        gonggaoList: [], // 政策法规 下的二级分类
        listBox: [], // 新闻列表
      }
    },
    components: {
      Header,
      Navbar,
      Footer,
      commonPagination
    },
    methods: {
      /**
       * 获取小类
       * @param bigTypeId
       */
      getSmallTypeList(params) {
        getNewsSmallTypeList(params).then((res) => {
          if (res.status) {
            this.gonggaoList = res.data
          }
        });
      },

      /**
       * 切换二级分类
       * 不传入二级分类索引，则重新请求整个公告大类
       * */
      changeSmallType(index) {
        let query = this.query
        query.nowPage = 1
        var smallType = this.gonggaoList[index]
        this.currentSmallTypeName = smallType.smallTypeName
        this.currentIndex = index
        query.smallTypeId = smallType.smallTypeId
        this.getNewsList(query)
        this.query = query
      },

      /**
       * 获取新闻
       */
      getNewsList(params) {
        getNewsListPage(params).then((res) => {
          if (res.status) {
            let list = res.data
            for(var i=0;i<list.length;i++) {
              list[i].createTime = list[i].createTime.substring(0,10).replaceAll("-","/")
            }
            this.listBox = list
            this.query.allCount = res.total
          }
        });
      },

      handleSizeChange(val) {
        this.query.pageSize = val;
        this.getNewsList(this.query);
      },
      handleCurrentChange(val) {
        this.query.pageNum = val;
        this.getNewsList(this.query);
      },

      /**
       * 打开新闻详情
       * */
      openNews(newsId) {
        let routeUrl = this.$router.resolve({
          path: "/newsDetails",
          query: {
            newsId: newsId,
          },
        });
        window.open(routeUrl.href, "_blank");
      },
    },
    filters: {
     /**
         * 兼容ie的多行超出显示省略号
         * value:需要截取的字符串
         * num:需要截取的长度
         */
        ellipsis(value, num) {
          if (value.length > num) {
            return value.substring(0, num) + "...";
          } else {
            return value;
          }
        },
      },
    mounted() {
      // 查询左侧菜单
      this.getSmallTypeList({indexName: this.query.indexName});
      if (this.$route.query.keyword != null) {
        this.query.keyword = this.$route.query.keyword
      }
      // 查询新闻列表
      this.getNewsList(this.query)
    }
  };
</script>

<style>
  @import '~@/assets/css/common.css';
  @import '~@/assets/css/index.css';
  @import '~@/assets/css/list.css';
</style>
<style type="text/css" scoped>
  .center-list-title .iconfont {
    color: #2886bd;
  }
</style>
